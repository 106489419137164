@use '../common/decoration';
@use '../common/layout';

%spotting-pattern {
  p,
  ul {
    font-size: .7rem;
  }

  ul {
    margin-bottom: .5rem;
  }

  li p {
    margin-bottom: 0;
  }
}

/////////////////////////////////////////////////////////////

#biochips-and-microarrays{
  @extend %golden-section-hero-golden;
}

/////////////////////////////////////////////////////////////

.fluorescence-palette {
    @include layout.side-by-side-figures(0);

    .palette {
      grid-column: 1/3;
      grid-row: 2/3;
      img {
        max-height: 1.5rem;
      }
    }

    p.caption {
      grid-row: 3/4;
    }
  }

/////////////////////////////////////////////////////////////

.cea2-protocol {
  justify-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  img {
    width: unset;
  }
}

/////////////////////////////////////////////////////////////

// #parameter-study-protocol-optimization {

//   column-width: 25ch;
//   column-gap: 2rem;

//   margin-top: 2rem;
//   margin-bottom: 3rem;

//   h3,
//   & > p {
//     column-span: all;
//     max-width: 100ch;
//     margin-left: auto;
//     margin-right: auto;
//   }

//   section {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 2rem;
//     box-shadow: 0 0 .5em #AAA;
//     border-radius: .3em;

//     h4 {
//       margin-top: .5rem;
//       border-bottom: 1px solid #AAA;
//     }

//     p {
//       font-size: .9rem;
//       line-height: 1.1rem;
//       margin-bottom: .8rem;
//     }

//     h4,
//     & > p {
//       margin-left: .7rem;
//       margin-right: .7rem;
//     }

//     ul {
//       margin-right: .7rem;
//     }
//   }

//   section,
//   section * {
//     break-inside: avoid;
//   }

//   .spotting-pattern {
//     @extend %spotting-pattern;
//   }

// }

.spotting-pattern {
  @extend %spotting-pattern;
}

/////////////////////////////////////////////////////////////

.reaction-kinetics-diagram img {
  max-width: 45ch;
  justify-self: center;
}

/////////////////////////////////////////////////////////////

#neutron-reflectometry {
  table {

    th, td {
      text-align: center;
    }

    th:first-of-type, td:first-of-type {
      text-align: left;
    }
  }
}

/////////////////////////////////////////////////////////////

.vapor-phase-diagram img {
  max-width: 20ch;
  display: block;
  margin: auto;
}

/////////////////////////////////////////////////////////////

#atomic-force-microscopy {
  .figures {
    @include layout.side-by-side-figures(0, 3);
  }
}

/////////////////////////////////////////////////////////////
