@use 'sass:math';

// base unit aka font size for <p>
$base-unit: calc(1.2rem + .5vw);

$primary-color: rgb(158, 0, 0);
$primary-color-faded: rgb(222, 120, 120);
$primary-color-faded2: rgb(234, 180, 180);
$primary-color-faded3: rgb(240, 216, 216);
$body-font-color: #333;
$breakpoint-large: 1024px;
$breakpoint-medium: 750px;
$breakpoint-small: 450px;

$phi: math.div(1 + math.sqrt(5), 2);
$golden1: math.div(1, $phi);
$golden2: math.div(1, math.pow($phi, 2));
// $golden3: 1 / ( math.pow($phi, 3) );
// $golden4: 1 / ( math.pow($phi, 4) );
$main-content-max-width: 65ch;

body {
  //background-color: #eee7d9;
  //background-color: #fdf6e3;
  background-color: #fafafa;
}

// For reference:
// $breakpoints: (
//   small: 0,
//   medium: 640px,
//   large: 1024px,
//   xlarge: 1200px,
//   xxlarge: 1440px,
// ) !default;
