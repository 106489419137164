@use 'sass:math';
@use '../modules/clicky_menus';
@use 'settings' as *;
@use 'layout';

#masthead {
  margin-bottom: 0;

  a {
    text-decoration: none;
  }

  // border separator between site name and language navigation

  &::after {
    content: '';
    border-left: 1px solid $primary-color;
    height: #{$golden1 * 100%};
    grid-column: 8/9;
    grid-row: 1/2;
    align-self: center;
    justify-self: start;
  }

  @media screen and (min-width: $breakpoint-medium) {

  }
}

// Site name

#site-name {
  grid-column: main-content;
  align-self: center;
  grid-row: 1/2;
  justify-self: start;

  display: flex;
  flex-direction: row;
  column-gap: .5rem;
}

#site-name-first-initial {
  @media screen and (min-width: $breakpoint-small) {
    display: none;
  }
}
#site-name-first {
  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }
}

// Language selector

#languages {
  grid-column: side-content;
  align-self: center;
  grid-row: 1/2;
  justify-self: end;

  li {
    z-index: 10;
  }
}

#current-language {
  display: flex;
  flex-direction: row;
  column-gap: .5rem;
}
  
#language-selector-icon {
  height: 1.7rem;
  width: 1.7rem;
  margin-top: .4rem;
  opacity: 0.67;
}

#language-selector-caret {
  height: 1rem;
  width: 1rem;
  margin-top: .7rem;
}

#language-selector-current-language {
  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }
}

.masthead-enabled-item::before {
  content: '›';
  padding-right: .5rem;
}

.masthead-disabled-item {

  opacity: 0.6;
  cursor: not-allowed;;

  &::before {
    padding-right: .5rem;
    content: '⦸';
  }
}


// Common rules for all h1 titles (with or without hero image)

%article-header-common {

  h1,
  #article-subheader,
  img,
  figcaption {
    grid-column: full;
  }

  h1 {
    grid-column: 2/13;
    justify-self: start;
    grid-row: header-golden-large;
    align-self: end;
    z-index: 3;
    margin-bottom: 0;

    &::after {
      content: "";
      border-bottom: 1px solid $primary-color;
      display: block;
      //we want the width of this border to be in a golden relationship with the whitespace on either side of it
      width: #{$golden1 * 100%};
      //margin-left: #{math.div($golden1, 2) * 100%};
    }
  }

  #article-subheader {
    grid-column: 2/13;
    justify-self: start;
    grid-row: header-golden-small;
    align-self: start;
    z-index: 3;
  }

  @media screen and (min-width: $breakpoint-medium) {

  }
}

// Rules for default h1 header without hero image

#article-header-default {
  @extend %article-header-common;

  // similar to article-header-hero but taking up less vertical space because we only have a title (and maybe subtitle) but no graphic spread
  grid-template-rows:
  // total height of the graphic spread is 100% of the viewport's height
  [header-start header-golden-full-start header-golden-large-start] minmax(min-content, #{$golden1 * 100}vh)  // top golden zone (smaller)
  [header-golden-large-end header-golden-small-start] minmax(min-content, #{$golden2 * 100}vh)  // bottom golden zone (larger)
  [header-golden-small-end header-golden-full-end figcaption-start] min-content                         // figcaption
  [figcation-end header-end] ;

  @media screen and (min-width: $breakpoint-medium) {
    grid-template-rows:
    // total height of the graphic spread is 85% of the viewport's height
    [header-start header-golden-full-start header-golden-large-start] repeat(4, minmax(min-content, #{math.div($golden1 * 50, 4)}vh))  // top golden zone (smaller)
    [header-golden-large-end header-golden-small-start] repeat(3, minmax(min-content, #{math.div($golden2 * 50, 3)}vh))  // bottom golden zone (larger)
    [header-golden-small-end header-golden-full-end figcaption-start] min-content                         // figcaption
    [figcation-end header-end] ;
  }
}

// Rules for h1 header with hero image

#article-header-hero {
  @extend %article-header-common;

  // same proportions as %golden-section-hero-golden, but with a few rows in each for flexibility (like adding the masthead)
  grid-template-rows:
  // total height of the graphic spread is 100% of the viewport's height
  [header-start header-golden-full-start header-golden-large-start] minmax(min-content, #{$golden1 * 100}vh)  // top golden zone (smaller)
  [header-golden-large-end header-golden-small-start] minmax(min-content, #{$golden2 * 100}vh)  // bottom golden zone (larger)
  [header-golden-small-end header-golden-full-end figcaption-start] min-content                         // figcaption
  [figcation-end header-end] ;

  @media screen and (min-width: $breakpoint-medium) {
    grid-template-rows:
    // total height of the graphic spread is 85% of the viewport's height
    [header-start header-golden-full-start header-golden-large-start] repeat(4, minmax(min-content, #{math.div($golden1 * 85, 4)}vh))  // top golden zone (smaller)
    [header-golden-large-end header-golden-small-start] repeat(3, minmax(min-content, #{math.div($golden2 * 85, 3)}vh))  // bottom golden zone (larger)
    [header-golden-small-end header-golden-full-end figcaption-start] min-content                         // figcaption
    [figcation-end header-end] ;
  }

  img {
    grid-row: header-golden-full;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    //filter: brightness(60%);
  }

  h1,
  #article-subheader {
    color: #f3f3f3;
    text-shadow: 0 0 1.5rem #000,0 0 1rem #000;
  }

  // box-shadows to simulate depth, similar to layout.golden-section
  &::before {
    content: '';
    grid-row: header-golden-full;
    grid-column: full;
    box-shadow: 0 0 2rem 0 #44000077;
    z-index: 4;
  }

  // gradient for better legibility of text on the left side. Pseudo-element needs to be a child of the grid in order to be placed on it.

  &::after {
    content: '';
    background: linear-gradient(60deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0) 100%);
    // TODO: see if we can figure out the proper angle of the gradient by using viewport units and arctangent
    grid-column: full;
    grid-row: header-golden-full;
    z-index: 2;
  }

  @media screen and (min-width: $breakpoint-medium) {

    // Slightly different gradient on larger viewports, to obscure the image less on the right side

    &::after {
      background: linear-gradient(60deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0) 80%, rgba(0,0,0,0) 100%);
    }
  }
}
