$fonts-dir: "/assets/fonts";

/**
 * Font-face mixin to simplify the following.
 * links for reference:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face
 * https://css-tricks.com/snippets/css/using-font-face-in-css/
 * https://www.paulirish.com/2009/bulletproof-font-face-implementation-syntax/
 */

@mixin font-face ($family, $file-prefix, $weight: normal, $style: normal) {
	@font-face {
		font-family: $family;
		src: url("#{$fonts-dir}/#{$file-prefix}.eot");
		src: 
			local("#{$family}"),
			url("#{$fonts-dir}/#{$file-prefix}.eot?#iefix") format("embedded-opentype"),
			url("#{$fonts-dir}/#{$file-prefix}.woff2") format("woff2"),
			url("#{$fonts-dir}/#{$file-prefix}.woff") format("woff"),
			url("#{$fonts-dir}/#{$file-prefix}.svg##{$file-prefix}") format("svg"),
			url("#{$fonts-dir}/#{$file-prefix}.ttf") format("truetype");
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

/**
 * Linux Libertine
 */

@include font-face(
	'Linux Libertine Display Regular',
	'linux-libertine/LinLibertine_DR',
	normal,
	normal
);

@include font-face(
	'Linux Libertine Initials',
	'linux-libertine/LinLibertine_I',
	normal,
	normal
);

@include font-face(
	'Linux Libertine Mono',
	'linux-libertine/LinLibertine_M',
	normal,
	normal
);

@include font-face(
	'Linux Libertine Regular',
	'linux-libertine/LinLibertine_R',
	normal,
	normal
);

@include font-face(
	'Linux Libertine Regular Bold',
	'linux-libertine/LinLibertine_RB',
	bold,
	normal
);

@include font-face(
	'Linux Libertine Regular Bold Italic',
	'linux-libertine/LinLibertine_RBI',
	bold,
	italic
);

@include font-face(
	'Linux Libertine Regular Italic',
	'linux-libertine/LinLibertine_RI',
	normal,
	italic
);

@include font-face(
	'Linux Libertine Semibold',
	'linux-libertine/LinLibertine_RZ',
	600,
	normal
);

@include font-face(
	'Linux Libertine Semibold Italic',
	'linux-libertine/LinLibertine_RZI',
	600,
	italic
);

$phenix-serif: 'Linux Libertine Regular', 'Baskerville', 'Georgia', serif;

/**
 * Linux Biolinum
 */

@include font-face(
	'Linux Biolinum Keyboard',
	'linux-biolinum/LinBiolinum_K',
	normal,
	normal
);

@include font-face(
	'Linux Biolinum Regular',
	'linux-biolinum/LinBiolinum_R',
	normal,
	normal
);

@include font-face(
	'Linux Biolinum Regular Bold',
	'linux-biolinum/LinBiolinum_RB',
	bold,
	normal
);

@include font-face(
	'Linux Biolinum Regular Italic',
	'linux-biolinum/LinBiolinum_RI',
	normal,
	italic
);

$phenix-sans: 'Linux Biolinum Regular', 'Futura', 'Verdana', sans-serif;

/**
 * Latin Modern Monospace
 */

@include font-face(
	'Latin Modern Monospace Regular',
	'latinmodern/mono/lmmono10-regular-webfont',
	normal,
	normal
);

@include font-face(
	'Latin Modern Monospace Italic',
	'latinmodern/mono/lmmono10-italic-webfont',
	normal,
	italic
);

@include font-face(
	'Latin Modern Monospace Light Bold',
	'latinmodern/mono/lmmonolt10-bold-webfont',
	bold,
	normal
);

@include font-face(
	'Latin Modern Monospace Light Bold Oblique',
	'latinmodern/mono/lmmonolt10-boldoblique-webfont',
	bold,
	italic
);

$phenix-monospace: 'Latin Modern Monospace Regular', 'Courier', monospace;

/**
 * phenix Serif
 */

%phenix-serif {
    font-family: $phenix-serif;
    font-style: normal;
	font-weight: normal;
}

%phenix-serif-bold {
    font-family: 'Linux Libertine Regular Bold', 'Baskerville', 'Georgia', serif;
    font-style: normal;
    font-weight: bold;
}

%phenix-serif-italic {
    font-family: 'Linux Libertine Regular Italic', 'Baskerville', 'Georgia', serif;
    font-style: italic;
	font-weight: normal;
}

%phenix-serif-bolditalic {
    font-family: 'Linux Libertine Regular Bold Italic', 'Baskerville', 'Georgia', serif;
    font-style: italic;
    font-weight: bold;
}

%phenix-serif-display {
    font-family: 'Linux Libertine Display Regular', 'Baskerville', 'Georgia', serif;
}

%phenix-serif-semibold {
    font-family: 'Linux Libertine Semibold', 'Baskerville', 'Georgia', serif;
    font-style: normal;
    font-weight: 600;
}

%phenix-serif-semibold-italic {
    font-family: 'Linux Libertine Semibold Italic', 'Baskerville', 'Georgia', serif;
    font-style: italic;
    font-weight: 600;
}

/**
 * phenix Sans
 */

%phenix-sans {
    font-family: $phenix-sans;
    font-style: normal;
	font-weight: normal;
}

%phenix-sans-bold {
    font-family: 'Linux Biolinum Regular Bold', 'Futura', 'Verdana', sans-serif;
    font-style: normal;
    font-weight: bold;
}

%phenix-sans-italic {
    font-family: 'Linux Biolinum Regular Italic', 'Futura', 'Verdana', sans-serif;
    font-style: italic;
	font-weight: normal;
}

/**
 * phenix Monospace
 */

%phenix-monospace {
    font-family: $phenix-monospace;
    font-style: normal;
	font-weight: normal;
}

%phenix-monospace-italic {
    font-family: 'Latin Modern Monospace Italic', 'Courier', monospace;
    font-style: italic;
	font-weight: normal;
}

%phenix-monospace-bold {
    font-family: 'Latin Modern Monospace Light Bold', 'Courier', monospace;
    font-style: normal;
    font-weight: bold;
}

%phenix-monospace-bolditalic {
    font-family: 'Latin Modern Monospace Light Bold Oblique', 'Courier', monospace;
    font-style: italic;
    font-weight: bold;
}
