@use '../modules/honeycomb';

/////////////////////////////////////////////////////////////

#team {
  @include honeycomb.create(5, 3, "side-content");

  .caption {
    grid-column: 5/10;
    grid-row: 13/16;
    align-self: end;
    text-align: right;
  }
}

$team-pics-placement:
// pic  half-column  row  x-position
1  2  1  25%,
2  3  2  75%,
3  2  3  65%,
4  4  3,
5  1  4;

@include honeycomb.place-set($team-pics-placement, "team");

#hopes-fears {
  @include honeycomb.create(3, 2, "side-content");

  .caption {
    grid-column: 1/5;
    grid-row: 8/9;
    align-self: end;
  }
}

#hopes {
  @include honeycomb.place-image(1, 1);
  object-position: 0% 50%;
}

#fears {
  @include honeycomb.place-image(2, 2);
  object-position: 100% 50%;
}

/////////////////////////////////////////////////////////////

#build-or-achieve-by-2030 {
  aspect-ratio: 1 / 1;
  align-self: center;

  height: 100%;
  @extend %become-hexagon;
  object-fit: cover;
  object-position: 15% 50%;
}

/////////////////////////////////////////////////////////////

#world-around-us {
  @include honeycomb.create(6, 3, "main-content");

  .caption {
    grid-column: 8/12;
    grid-row: 1/3;
    align-self: center;
    text-align: right;
  }
}

$world-around-us-pics-placement:
// pic  half-column  row
1  2  1,
2  1  2,
3  5  2,
4  2  3,
5  4  3;

@include honeycomb.place-set($world-around-us-pics-placement, "world-around-us");

// So it looks less creepy
#world-around-us5 {
  transform: scaleX(-1);
}

/////////////////////////////////////////////////////////////

#new-voices {
  @include honeycomb.create(4, 4, "side-content");

  .caption {
    grid-column: 5/8;
    grid-row: 11/14;
    align-self: center;
  }
}

$new-voices-pics-placement:
// pic  half-column  row
1  2  1 100%,
2  1  2,
3  3  2 40%,
4  2  3,
5  1  4 80%;

@include honeycomb.place-set($new-voices-pics-placement, "new-voices");

#new-voices2 {
  // Darken a little bit so it stands out from the page's white background
  filter: brightness(0.95);
}

/////////////////////////////////////////////////////////////

#wikimania2017 {
  @include honeycomb.create(4, 4, "side-content");

  .caption {
    grid-column: 1/4;
    grid-row: 11/14;
    align-self: center;
  }
}

$wikimania2017-pics-placement:
// pic  half-column  row
1  2  1 35%,
2  1  2,
3  3  2 70%,
4  2  3 60%,
5  3  4 5%;

@include honeycomb.place-set($wikimania2017-pics-placement, "wikimania2017-pic");

#wikimania2017-pic2 {
  // Darken a little bit so it stands out from the page's white background
  filter: brightness(0.95);
}

/////////////////////////////////////////////////////////////

#people {
  @include honeycomb.create(11, 8, "full-content");

  .caption {
    grid-column: 11/18;
    grid-row: 23/26;
    align-self: center;
  }
}

$people-pics-placement:
// pic  half-column  row
1  1  1 0%,
2  5  1,
3  9  1,
4  2  2,
5  4  2,
6  6  2,
7  8  2,
8  3  3,
9  7  3 25%,
10  2  4 100%,
11  4  4 25%,
12  6  4,
13  7  5 25%,
14  9  5,
15  4  6,
16  6  6,
17  8  6,
18  3  7,
19  5  7 100%,
20  9  7,
21  4  8,
22  10  8;

@include honeycomb.place-set($people-pics-placement, "people");
