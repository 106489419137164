@use 'settings' as *;
@use 'layout';

body > footer {

  @extend %golden-grid-skeleton;
  min-height: 0.5vh;
  background-color: rgb(80, 0, 0);
  color: #cca0a0;

  // Layout

  nav {
    grid-column: full-content;
    //Create a grid of our own for now; in the future maybe reuse the golden grid:
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $base-unit;
    padding: 2rem 0;

    section {
      grid-column: 1/4;
    }

    @media screen and (min-width: 100ch) {
      #footer-main-links {
        grid-column: 1/2;
        grid-row: 1/2;
      }

      #footer-elsewhere {
        grid-column: 2/4;
        grid-row: 1/2;
      }

      #footer-contact {
        grid-column: 1/4;
        grid-row: 2/3;
      }
    }
  }

  // Appearance

  a {
    color: #cca0a0;

    &:hover {
      color: #f2c8c8;
    }
  }

  h3 {
    text-align: left;
  }

  // Contact form

  #footer-contact {

    form p {
      display: grid;
      grid-template-columns: min-content 1fr;
      column-gap: 1rem;
      row-gap: 1rem;
    }

    // By default we stack everything
    label,
    input,
    textarea,
    button {
      grid-column: 1/3;
    }

    input, textarea {
      background-color: #cca0a0;
      border: none;
    }

    textarea {
      min-height: 8rem;
    }

    button {
      cursor: pointer;
      background-color: #8e0000;
      color: #cca0a0;
      min-height: 2rem;

      &:hover {
        color: #f2c8c8;
      }
    }

    input, textarea, button {
      border-radius: .2rem;
    }

    @media screen and (min-width: $breakpoint-medium) {

      // If we have more space, we can arrange them a bit more nicely:

      label {
        grid-column: 1/2;
      }

      input,
      textarea,
      button {
        grid-column: 2/3;
      }
    }

  }

}
