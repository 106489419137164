@use '../common/layout';

/* Legacy styles for when we had the responsive collage
header {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 4fr auto auto;
  grid-gap: .3rem;
  height: 80vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 25;
  }

  .image1 {
    grid-column: 1 / 5;
    grid-row: 1 / 4;
    object-position: 60% 50%;
    -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  }

  .image2 {
    grid-column: 4 / 8;
    grid-row: 1 / 4;
    object-position: 50% 75%;
    -webkit-clip-path: polygon(25% 0, 100% 0, 75% 100%, 0 100%);
    clip-path: polygon(25% 0, 100% 0, 75% 100%, 0 100%);
  }

  .image3 {
    grid-column: 7 / 11;
    grid-row: 1 / 4;
    object-position: 35% 50%;
    -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
  }

  .titleblock {
    grid-column: 2 / 7;
    grid-row: 2 / 3;
    z-index: 50;
    display:block;
    background-color: rgba(203, 0, 0, 0.78);
    color: #f3f3f3;
    padding: .5rem;
  }

  h1 {
    font-weight: bold;

    font-size: 10vw;

    @media (min-aspect-ratio: 1/1) {
      font-size: 5vw;
    }
  }

  p.dateline {
    grid-column: 2 / 5;
    grid-row: 3 / 4;
    z-index: 50;
    background-color: rgba(255, 255, 255, 0.65);
    font-variant-caps: small-caps;
    font-size: 1rem;
  }
} */

.fig-the-war-of-the-words img {
  object-position: 60% 50%;
}

.fig-success-into-oblivion img {
  object-position: 50% 75%;
}

.fig-success-into-oblivion img {
  object-position: 75% 50%;
}
