@use '../settings' as *;
@use '../common/media';

.page_wikimedia {
  .post-subtitle {
    display:block;
    background-color: rgba(228, 83, 31, 0.78);
    color: #f3f3f3;
    padding: .5rem;
    font-size: calc(2rem + 2vw);
    line-height: calc(2rem + 2vw);
  }
}

/////////////////////////////////////////////////////////////

/* .wikimedia-vision {
  height: 80vh;
  overflow: hidden;
  background-color: #000099;
  display: grid;
  grid-template-columns: minmax(0, 3fr) 4fr 1fr;
  grid-template-rows: minmax(0, 3fr) 4fr 1fr;
  margin-bottom: 2rem;

  p:first-child {
    font-size: calc(.5rem + .5vw);
    line-height: calc(.7rem + .5vw);
    color: #b3b3ff;
    grid-column: 1/4;
    grid-row: 1/3;
  }

  p:nth-child(2) {
    display: block;
    padding: .7rem;
    background-color: #00004d99;
    font-size: calc(1.5rem + 1.5vw);
    line-height: calc(1.5rem + 1.5vw);
    color: #f3f3f3;
    grid-column: 2/3;
    grid-row: 2/3;
    align-self: center;
  }
} */

/////////////////////////////////////////////////////////////
// Wikimedia photos slideshow / grid
/* 
#wikimedia-photos {
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, auto);

  img {

    @media screen and (max-width: $breakpoint-medium) {
      opacity: 0;
      @include media.slideshow-fade($num:5, $fade:2, $visible:0);
      grid-column: 1 / span 5;
      grid-row: 1 / 2;
    }

    @media screen and (min-width: $breakpoint-medium) {
      // TODO Do something more interesting with the layout since we have more space
      opacity: 0;
      @include media.slideshow-fade($num:5, $fade:2, $visible:0);
      grid-column: 1 / span 5;
      grid-row: 1 / 2;
    }
  }
} */
