@use '../common/decoration';
@use '../common/layout';

/////////////////////////////////////////////////////////////

.pnipam-formula img {
  max-width: 20rem;
  margin: auto;
}

.pnipam-switch-figures {
  @include layout.side-by-side-figures();
}

/////////////////////////////////////////////////////////////

.silane-afm {
  @include layout.side-by-side-figures(.5rem);
}
.functionalization-hydrophobic-hydrophilic {
  @include layout.side-by-side-figures();
}

/////////////////////////////////////////////////////////////

.chip-blender,
.ir-camera,
.catching-proteins-capillary,
.catching-proteins-chip {
  @include layout.side-by-side-figures(.3rem);
}
