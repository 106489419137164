// Simplest CSS slideshow by Jonathan Snook
// https://snook.ca/archives/html_and_css/simplest-css-slideshow

@use 'sass:math';

@mixin slideshow-fade($num:1, $fade:1, $visible:2) {
   $a: math.div(100, (($fade + $visible) * $num));
   @keyframes fade {
    0%   { opacity: 0; }
    #{$a * $fade}%   { opacity: 1; }
    #{$a * ($fade + $visible)}%  { opacity: 1; }
    #{$a * ($fade + $visible + $fade)}%  { opacity: 0; }
    100% { opacity: 0; }
  }

  animation-name: fade;
  animation-duration: (($fade + $visible) * $num) + s;
  animation-iteration-count: infinite;

  @for $i from 1 through $num {
    &:nth-child(#{$i}) {
      animation-delay: #{($fade + $visible) * ($i - 1) * 1s};
    }
  }
}

//////////////////////////////////////

img,
video {
  width: 100%;
  margin-bottom: .5rem;
}

figcaption, .caption {
  // we don't want too much space between images and the caption
  margin-top: -.8rem;
}
