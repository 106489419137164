@use '../settings' as *;

.framed,
.framed-img img {

  //margin-bottom: .5rem;
  border: 1px solid var(--primary-color-faded2);
  //margin-bottom: 0;
}

.flip-img {

  & img {
    transform: scaleX(-1);
  }
}

%graph-paper {
  // most for use in .expert below, but also in a few other places (like the technical abstract and the citations on the Ph.D page) so let's make it reusable.

  background: url('/assets/images/Graph_paper_inch_green_pattern.svg');
  border: 1px dashed #0B0;
  padding: .4rem;
  color: #050;
}

.expert {
  @extend %graph-paper;
}

/////////////////////////////////////////////////////////////

.technical-abstract {
  @extend %graph-paper;
  border: 2px dotted #0B0;
}


// Previously for:
// .article-list-featured hr, .project-list-featured hr

hr {
    background: url('/assets/images/Memoria_sullo_scavo_della_Via_Appia_fregio_p_10.png')  no-repeat top center;
    height: 122px;
    width: 100%;
    border:none !important;
    margin-top: calc(3vh + 3vw);
    margin-bottom: calc(3vh + 3vw);
}

.article-list hr {
    background: url('/assets/images/Paragraph_decoration.png')  no-repeat top center;
    height: 30px;
    border:none !important;
}


.article-list > hr:last-of-type,
.project-list-featured > hr:last-of-type {
    display: none;
}

//////////////////////////////////////////////////////////

// Experiments with decoration

%double-frame-inset {
  outline: .4rem double white;
  outline-offset: -1rem;
}

%subtle-shadow {
  box-shadow: rgba(0,0,0,.4) 0 .3rem 1rem;
}

// Tables

thead tr {
  background: var(--primary-color-faded2);
}

tbody tr:nth-child(even) {
  background: var(--primary-color-faded3);
}

