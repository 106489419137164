// Elements for screen readers only
// per https://webaim.org/techniques/css/invisiblecontent/#skipnavlinks

#skip-to-content a,
#footer-title
{
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

#skip-to-content a:focus
{
  position:static;
  width:auto;
  height:auto;
}
